/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import globalCookies from 'helpers/cookies'

/**********************************************************************************************************
 *   RTK QUERY BASE QUERY
 **********************************************************************************************************/
const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API,
    credentials: 'include',
    prepareHeaders: (headers) => {
        const token = globalCookies.get('XSRF-TOKEN')
        if (token) headers.set('X-XSRF-TOKEN', token)
        return headers
    }
})

export const mercuryBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    return { ...result, meta: result.meta }
}

export const TAG_TYPES = {
    INITIALISE_APPLICATION: 'InitialiseApplication',
    SESSION: 'Session',
    ACCOUNTS: 'Accounts',
    SELECTED_TWO_FACTOR: 'Selected-Two-Factor',
    USER: 'User',
    AUTHORISED_ACCOUNTS: 'Authorised-Accounts',
    TWO_FACTOR: 'Two-Factor',
    ADDITIONAL_USERS: 'Additional-Users',
    EMAIL_LOGS: 'Email-Logs',
    USER_SESSIONS: 'User-Sessions',
    SECURITY: 'Security',
    PAYMENT_METHODS_DATA: 'Payment-Methods-Data',
    INVOICES: 'Invoices',
    INVOICE: 'Invoice',
    DOMAINS: 'Domains',
    DOMAIN: 'Domain',
    RECORDS: 'Records',
    DOMAIN_RENEWAL: 'Domain-Renewal',
    DOMAIN_CONTACTS: 'Domain-Contacts',
    DOMAIN_PRESETS: 'Domain-Presets',
    ELIGIBILITY: 'Eligibility',
    EPP_CODE: 'EPP-Code',
    SERVICES: 'Services',
    SERVICE: 'Service',
    AVAILABLE_PLANS: 'Available-Plans',
    CANCELLATION_REQUESTS: 'Cancellation-Requests',
    CHANGE_PLAN_STATUS: 'Change-Plan-Status',
    MODULE_META: 'Module-Meta',
    RENEW_PRICE: 'Renew-Price',
    BILLING_CYCLE: 'Billing-Cycle',
    CART: 'Cart',
    PRODUCT: 'Product',
    PRODUCT_GROUP: 'Product-Group',
    PRODUCT_LIST: 'Product-List',
    PAYMENT_METHODS: 'Payment-Methods',
    ACCOUNT_CREDIT: 'Account-Credit',
    LOGIN_SERVICE: 'Login-Service',

    EMAIL_REDIRECTS: 'Email-Redirects',
    DOMAIN_REDIRECTS: 'Domain-Forwarders'
} as const

const tagTypes = Object.values(TAG_TYPES)
export type TTagTypes = (typeof tagTypes)[number]

export const baseApi = createApi({
    tagTypes,
    baseQuery: mercuryBaseQuery,
    endpoints: () => ({})
})
